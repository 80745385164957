"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDims = exports.setThemeMode = exports.removeNotification = exports.addNotification = exports.toggleHiddenQuestionsDisplay = exports.setHiddenQuestionsDisplay = exports.widgetCompleted = exports.testResizedToMin = exports.detectScroll = exports.incrementSeqNum = exports.leftLastPage = exports.sendScreenout = exports.moveNextPage = exports.setCurrentPage = exports.pageSwitched = exports.resize = exports.surveySkip = exports.clickPreviousPage = exports.clickNextPage = exports.doRedirect = exports.initMetrikaCounter = exports.setLang = exports.setFailureSurvey = exports.setInitedSurvey = exports.startInterview = exports.sendAnswersToMetrika = exports.scrollToQuestion = exports.showQuestionsErrors = exports.loadSurveyFromConfig = exports.restoreSurveyFromPreloadedState = exports.getActiveRevisionId = exports.getSurveyRequest = exports.storePageSwitch = exports.sendAnswerBulkRequest = exports.sendAnswerShownQuestion = exports.sendAnswerRequest = exports.finishSurveyRequest = exports.endVideoQuestion = exports.drawImageQuestionClickmap = exports.clickImageQuestionClickmap = exports.clickNoOpinionAnswerQuestion = exports.clickNoAnswerQuestion = exports.changeOtherWithTextQuestion = exports.clickOtherWithTextQuestion = exports.clickOtherQuestion = exports.clickTableOptionQuestion = exports.clickOptionQuestion = exports.changeQuestionText = exports.blurQuestionText = void 0;
const blurQuestionText = (questionIndex, text, options) => {
    return Object.assign({ type: 'QUESTION_BLUR_TEXT', questionIndex,
        text }, options);
};
exports.blurQuestionText = blurQuestionText;
const changeQuestionText = (questionIndex, text, options) => {
    return Object.assign({ type: 'QUESTION_CHANGE_TEXT', questionIndex,
        text }, options);
};
exports.changeQuestionText = changeQuestionText;
const clickOptionQuestion = (questionIndex, optionIndex) => {
    return {
        type: 'QUESTION_CLICK_OPTION',
        value: optionIndex,
        questionIndex
    };
};
exports.clickOptionQuestion = clickOptionQuestion;
const clickTableOptionQuestion = (questionIndex, boundQIndex, optionIndex) => {
    return {
        type: 'QUESTION_TABLE_CLICK_OPTION',
        value: {
            optionIndex,
            boundQIndex
        },
        questionIndex
    };
};
exports.clickTableOptionQuestion = clickTableOptionQuestion;
const clickOtherQuestion = (questionIndex) => {
    return {
        type: 'QUESTION_CLICK_OTHER',
        questionIndex
    };
};
exports.clickOtherQuestion = clickOtherQuestion;
const clickOtherWithTextQuestion = (questionIndex) => {
    return {
        type: 'QUESTION_CLICK_OTHER_WITH_TEXT',
        questionIndex
    };
};
exports.clickOtherWithTextQuestion = clickOtherWithTextQuestion;
const changeOtherWithTextQuestion = (questionIndex, value, options) => {
    return Object.assign({ type: 'QUESTION_CHANGE_OTHER_WITH_TEXT', questionIndex,
        value }, options);
};
exports.changeOtherWithTextQuestion = changeOtherWithTextQuestion;
const clickNoAnswerQuestion = (questionIndex) => {
    return {
        type: 'QUESTION_CLICK_NO_ANSWER',
        questionIndex
    };
};
exports.clickNoAnswerQuestion = clickNoAnswerQuestion;
const clickNoOpinionAnswerQuestion = (questionIndex) => {
    return {
        type: 'QUESTION_CLICK_NO_OPINION_ANSWER',
        questionIndex
    };
};
exports.clickNoOpinionAnswerQuestion = clickNoOpinionAnswerQuestion;
const clickImageQuestionClickmap = (questionIndex, event) => {
    return {
        type: 'QUESTION_CLICKMAP_IMAGE_CLICK',
        questionIndex,
        value: event
    };
};
exports.clickImageQuestionClickmap = clickImageQuestionClickmap;
const drawImageQuestionClickmap = (questionIndex, event) => {
    return {
        type: 'QUESTION_CLICKMAP_IMAGE_DRAW',
        questionIndex,
        value: event
    };
};
exports.drawImageQuestionClickmap = drawImageQuestionClickmap;
const endVideoQuestion = (questionIndex, event) => {
    return {
        type: 'QUESTION_VIDEO_END',
        questionIndex,
        value: event
    };
};
exports.endVideoQuestion = endVideoQuestion;
const finishSurveyRequest = (slug, answers) => {
    return {
        type: 'REQUEST_TO_API',
        steps: ['SURVEY_FINISH_PENDING', 'SURVEY_FINISH_SUCCESS', 'SURVEY_FINISH_FAILURE'],
        params: {
            slug,
            data: answers
        },
        method: 'finishSurvey'
    };
};
exports.finishSurveyRequest = finishSurveyRequest;
const sendAnswerRequest = (slug, data) => {
    return {
        type: 'REQUEST_TO_API',
        steps: [
            'SURVEY_SEND_ANSWER_PENDING',
            'SURVEY_SEND_ANSWER_SUCCESS',
            'SURVEY_SEND_ANSWER_FAILURE'
        ],
        params: {
            slug,
            data
        },
        method: 'sendAnswer'
    };
};
exports.sendAnswerRequest = sendAnswerRequest;
const sendAnswerShownQuestion = (questionIndex) => {
    return {
        type: 'SEND_ANSWER_SHOWN_QUESTION',
        questionIndex
    };
};
exports.sendAnswerShownQuestion = sendAnswerShownQuestion;
const sendAnswerBulkRequest = (slug, data) => {
    return {
        type: 'REQUEST_TO_API',
        steps: [
            'SURVEY_SEND_ANSWER_BULK_PENDING',
            'SURVEY_SEND_ANSWER_BULK_SUCCESS',
            'SURVEY_SEND_ANSWER_BULK_FAILURE'
        ],
        params: {
            slug,
            data
        },
        method: 'sendAnswerBulk'
    };
};
exports.sendAnswerBulkRequest = sendAnswerBulkRequest;
const storePageSwitch = (slug, pageFrom, pageTo) => {
    return {
        type: 'REQUEST_TO_API',
        steps: [
            'SURVEY_PAGE_SWITCH_PENDING',
            'SURVEY_PAGE_SWITCH_SUCCESS',
            'SURVEY_PAGE_SWITCH_FAILURE'
        ],
        params: {
            slug,
            data: {
                pageFrom,
                pageTo
            }
        },
        method: 'pageSwitch'
    };
};
exports.storePageSwitch = storePageSwitch;
const getSurveyRequest = (slug, queryString) => {
    return {
        type: 'REQUEST_TO_API',
        steps: ['SURVEY_GET_PENDING', 'SURVEY_GET_SUCCESS', 'SURVEY_GET_FAILURE'],
        params: {
            slug,
            queryString
        },
        method: 'getSurvey'
    };
};
exports.getSurveyRequest = getSurveyRequest;
const getActiveRevisionId = (slug) => {
    return {
        type: 'REQUEST_TO_API',
        steps: ['REVISION_ID_GET_PENDING', 'REVISION_ID_GET_SUCCESS', 'REVISION_ID_GET_FAILURE'],
        params: {
            slug
        },
        method: 'getActiveRevisionId'
    };
};
exports.getActiveRevisionId = getActiveRevisionId;
const restoreSurveyFromPreloadedState = () => {
    return {
        type: 'SURVEY_RESTORED_FROM_PRELOADED_STATE'
    };
};
exports.restoreSurveyFromPreloadedState = restoreSurveyFromPreloadedState;
const loadSurveyFromConfig = (slug, revision, status) => {
    return {
        type: 'SURVEY_LOADED_FROM_CONFIG',
        params: {
            slug
        },
        data: revision,
        status
    };
};
exports.loadSurveyFromConfig = loadSurveyFromConfig;
const showQuestionsErrors = questionsIndexes => {
    return {
        type: 'QUESTIONS_SHOW_ERRORS',
        questionsIndexes
    };
};
exports.showQuestionsErrors = showQuestionsErrors;
const scrollToQuestion = (questionIndex) => {
    return {
        type: 'SCROLL_TO_QUESTION',
        questionIndex
    };
};
exports.scrollToQuestion = scrollToQuestion;
const sendAnswersToMetrika = () => {
    return {
        type: 'METRIKA_SEND_ANSWERS'
    };
};
exports.sendAnswersToMetrika = sendAnswersToMetrika;
const startInterview = ({ _ivid, _uid }) => {
    return {
        type: 'INTERVIEW_STARTED',
        data: {
            _uid,
            _ivid
        }
    };
};
exports.startInterview = startInterview;
const setInitedSurvey = (data, params, status) => {
    return {
        type: 'SURVEY_SET_INITED',
        data,
        params,
        status
    };
};
exports.setInitedSurvey = setInitedSurvey;
const setFailureSurvey = (data, params, status) => {
    return {
        type: 'SURVEY_SET_FAILURE',
        data,
        params,
        status
    };
};
exports.setFailureSurvey = setFailureSurvey;
const setLang = () => {
    return {
        type: 'LANG_SET'
    };
};
exports.setLang = setLang;
const initMetrikaCounter = () => {
    return {
        type: 'METRIKA_INIT'
    };
};
exports.initMetrikaCounter = initMetrikaCounter;
const doRedirect = (url) => {
    return {
        type: 'REDIRECT',
        url
    };
};
exports.doRedirect = doRedirect;
const clickNextPage = () => {
    return {
        type: 'CLICK_NEXT_PAGE'
    };
};
exports.clickNextPage = clickNextPage;
const clickPreviousPage = () => {
    return {
        type: 'CLICK_PREVIOUS_PAGE'
    };
};
exports.clickPreviousPage = clickPreviousPage;
const surveySkip = () => {
    return {
        type: 'SURVEY_SKIP'
    };
};
exports.surveySkip = surveySkip;
const resize = () => {
    return {
        type: 'RESIZE'
    };
};
exports.resize = resize;
const pageSwitched = (pageNumber) => {
    return {
        type: 'PAGE_SWITCHED',
        data: { pageNumber }
    };
};
exports.pageSwitched = pageSwitched;
const setCurrentPage = (pageNumber) => {
    return {
        type: 'SET_CURRENT_PAGE',
        data: { pageNumber }
    };
};
exports.setCurrentPage = setCurrentPage;
const moveNextPage = ({ from, to }) => {
    return {
        type: 'MOVE_NEXT_PAGE',
        to,
        from
    };
};
exports.moveNextPage = moveNextPage;
const sendScreenout = ({ slug, pageFrom, queryString, optionIds }) => {
    return {
        type: 'REQUEST_TO_API',
        steps: ['SURVEY_SCREENOUT_PENDING', 'SURVEY_SCREENOUT_SUCCESS', 'SURVEY_SCREENOUT_FAILURE'],
        params: {
            slug,
            queryString,
            data: {
                pageFrom,
                optionIds
            }
        },
        method: 'screenout'
    };
};
exports.sendScreenout = sendScreenout;
const leftLastPage = () => {
    return {
        type: 'LEFT_LAST_PAGE'
    };
};
exports.leftLastPage = leftLastPage;
const incrementSeqNum = () => {
    return {
        type: 'SURVEY_INCREMENT_SEQ_NUM'
    };
};
exports.incrementSeqNum = incrementSeqNum;
const detectScroll = () => {
    return {
        type: 'DETECTED_SCROLL_ON_SURVEY'
    };
};
exports.detectScroll = detectScroll;
const testResizedToMin = () => {
    return {
        type: 'TEXT_RESIZED_TO_MIN'
    };
};
exports.testResizedToMin = testResizedToMin;
const widgetCompleted = (widgetId) => {
    return {
        type: 'WIDGET_COMPLETED',
        widgetId
    };
};
exports.widgetCompleted = widgetCompleted;
const setHiddenQuestionsDisplay = (value) => {
    return {
        type: 'SET_HIDDEN_QUESTIONS_DISPLAY',
        value
    };
};
exports.setHiddenQuestionsDisplay = setHiddenQuestionsDisplay;
const toggleHiddenQuestionsDisplay = () => {
    return {
        type: 'TOGGLE_HIDDEN_QUESTIONS_DISPLAY'
    };
};
exports.toggleHiddenQuestionsDisplay = toggleHiddenQuestionsDisplay;
const addNotification = (message) => ({
    type: 'ADD_NOTIFICATION',
    message
});
exports.addNotification = addNotification;
const removeNotification = () => ({
    type: 'REMOVE_NOTIFICATION'
});
exports.removeNotification = removeNotification;
const setThemeMode = (mode) => ({
    type: 'SET_THEME_MODE',
    mode
});
exports.setThemeMode = setThemeMode;
const setDims = (dims) => ({
    type: 'SET_DIMS',
    dims
});
exports.setDims = setDims;
