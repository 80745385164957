"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.choice = exports.getOptionStyles = void 0;
const react_1 = require("@emotion/react");
const getOptionStyles = () => (0, react_1.css) `
    width: 100%;
    text-align: start;
    box-sizing: border-box;

    min-height: var(--choice-option-height);

    & + .choice__option,
    & + .choice__other-answer-wrap {
        margin-top: var(--choice-option-margin-top);
    }

    & + .choice__other-answer-wrap {
        position: relative;
    }

    &.choice__option {
        display: flex;
        padding-left: var(--choice-option-padding-left);
    }

    .markdown p {
        margin: 0;
    }

    input {
        opacity: 0;
        height: 0;
        width: 0;
        position: absolute;
        left: -10000px;
    }

    .choice__option-text {
        flex: 1;
        overflow: hidden;
        padding-inline-start: var(--choice-option-padding-inline-start);
        padding-top: var(--choice-option-padding-top);
        vertical-align: top;
      
    }

    .choice__option-label {
        display: flex;
        flex-direction: var(--choice-option-flex-direction);
        flex: 1;
        cursor: pointer;
        justify-content: space-between;
        align-items: var(--choice-option-align-items);
        gap: var(--choice-option-gap);
        min-height: calc(var(--choice-option-control-width) + var(--radiobox-size-m-positionTop));
        color: var(--choice-option-text-color);
    }

    &.choice__option_hidden .choice__option-label {
        padding-bottom: 3px;
    }

    .choice__option-label .markdown p {
        line-height: inherit !important;
        font-size: var(--choice-option-label-font-size) !important;
        font-weight: var(--choice-option-label-font-weight);
        margin: 0 !important;
    }

    // контрол основные настройки

    .choice__option-control {
        box-sizing: border-box;
        margin-inline-end: var(--choice-option-margin-inline-end);
        position: relative;
        background-color: var(--choice-option-control-bg-color);
        border: var(--view-default-control-border);
        border-radius: 50%;
        top: var(--radiobox-size-m-positionTop);
        width: var(--choice-option-control-width);
        min-width: var(--choice-option-control-width);
        height: var(--choice-option-control-width);
    }

    .choice__option-control_focusVisible {
        outline: 2px solid var(--choice-option-control-focus-visible-border-color);
        outline-offset: 2px;
    }

    // контрол чекбокса
    .choice__option-control.choice__option-control_multiple_yes {
        width: var(--choice-option-control-width);
        min-width: var(--choice-option-control-width);
        height: var(--choice-option-control-width);
        border-radius: var(--multi-choice-option-control-border-radius);
    }

    &.choice__option_hidden {
        background-color: rgba(246, 248, 251, 1);
        padding: 2px 12px;
        border-radius: 12px;
        position: relative;
        margin-inline: -12px;
        width: calc(100% + 24px);
        flex-direction: row-reverse;
        align-items: center;
    }

    // контрол отмеченный
    .choice__option-control.choice__option-control_checked {
        background-color: var(--view-default-control-checked-background);
    }

    // оформление изнутри контрола
    .choice__option-control:before {
        content: '';
        display: block;
        box-sizing: border-box;
        cursor: pointer;
        background-size: contain;
        position: absolute;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-color: var(--choice-option-unchecked-bg);
        top: calc(50% - var(--radiobox-size-m-after-size) / 2);
        left: calc(50% - var(--radiobox-size-m-after-size) / 2);
        width: var(--radiobox-size-m-after-size);
        height: var(--radiobox-size-m-after-size);
    }

    .choice__option-control.choice__option-control_multiple_yes:before {
        border-radius: var(--multi-choice-option-control-border-radius);
    }

    // оформление изнутри контрола отмеченного
    .choice__option-control.choice__option-control_checked:before {
        opacity: 1;
        transform: var(--choice-option-checked-transform);
        background-color: var(--view-default-fill-color-internal);
    }

    .choice__option-control_checked {
        border: 0;
    }

    // чекбокс отмеченный, убрать точку внутри из радио кнопки
    .choice__option-control_multiple_yes.choice__option-control_checked:before {
        background: unset;
    }

    // стрелочка для отмеченного чекбокса
    .choice__option-control_multiple_yes.choice__option-control_checked:after {
        content: '';
        position: absolute;
        display: block;
        margin: 0 auto;
        left: var(--choice-option-checkmark-left);
        top: var(--choice-option-checkmark-top);
        transform: rotate(45deg);
        height: var(--choice-option-checkmark-long-side-width);
        width: var(--choice-option-checkmark-short-side-width);
        border-bottom: var(--choice-option-checkmark-weight) solid
            var(--choice-option-checkmark-color);
        border-right: var(--choice-option-checkmark-weight) solid
            var(--choice-option-checkmark-color);
    }

    .choice__option-label_disabled .choice__option-control:before,
    .choice__option-label_disabled:hover {
        cursor: not-allowed;
    }

    .choice__option-label_disabled .choice__option-control {
        border: none;
        background-color: var(--choice-option-control-disabled-color);
    }
    .choice__option-label_disabled.choice__option-label {
        color: var(--choice-option-label-disabled-color);
    }
`;
exports.getOptionStyles = getOptionStyles;
exports.choice = (0, react_1.css) `
    width: 100%;
    max-width: 100%;

    .choice__attachments-wrapper {
        margin-top: 8px;
    }

    & + .choice {
        margin-top: 1em;
    }

    .choice__other-answer-wrap + .choice__option {
        margin-top: 1.5em;
    }
`;
