"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .markdown h1 {
        font-size: var(--markdown-h1-font-size);
        line-height: var(--markdown-h1-line-height);
        font-weight: var(--markdown-h1-font-weight);
    }

    [dir] .markdown h1 {
        margin: 0 0 0.3em;
    }

    .markdown h2 {
        font-size: var(--markdown-h2-font-size);
        line-height: var(--markdown-h2-line-height);
        font-weight: var(--markdown-h2-font-weight);
    }

    [dir] .markdown h2 {
        margin: 0 0 0.3em;
    }

    .markdown h3 {
        font-size: var(--markdown-h3-font-size);
        line-height: var(--markdown-h3-line-height);
        font-weight: var(--markdown-h3-font-weight);
    }

    [dir] .markdown h3 {
        margin: 0 0 0.3em;
    }

    .markdown p {
        font-size: var(--markdown-font-size);
        line-height: var(--markdown-line-height);
        font-weight: var(--markdown-font-weight);
    }

    [dir] .markdown p {
        margin: 0 0 0.3em;
        word-break: break-word;
    }

    .markdown mark {
        font-family: inherit;
    }

    [dir] .markdown mark {
        background: #e4e4e4;
        padding: 0 0.2em;
        border-radius: 0.2em;
    }

    .markdown img {
        display: block;
        max-width: 100%;
        max-height: 50vh;
    }

    .markdown a[href] {
        text-decoration-line: var(--markdown-href-text-decoration-line);
        text-decoration-style: var(--markdown-href-text-decoration-style);
        color: var(--markdown-href-color);
        transition: color 0.15s ease-out;
    }

    .markdown a[href]:hover {
        color: var(--markdown-href-hover-color);
    }

    .markdown .embed-responsive {
        position: relative;
        height: 0;
    }

    [dir] .markdown .embed-responsive {
        margin: 2.5em 0;
    }

    [dir] .markdown .embed-responsive-16by9 {
        padding-bottom: 56.25%;
    }

    .markdown .embed-responsive iframe {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    [dir='ltr'] .markdown .embed-responsive iframe {
        left: 0;
    }

    [dir='rtl'] .markdown .embed-responsive iframe {
        right: 0;
    }
`;
