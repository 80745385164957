"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function compileFontStyleString(branding) {
    const { mainFont: { color, defaultSelected } = {} } = branding;
    return `
        {
            ${defaultSelected
        ? `--main-font: ${defaultSelected}, Arial, Helvetica, sans-serif;`
        : ''}
            ${color ? `--text-color: ${color};` : ''}
        }
    `;
}
exports.default = compileFontStyleString;
