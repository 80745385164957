"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileChoiceStyleString(branding) {
    const { choice = {} } = branding;
    const { option: { alignItems, disabledColor, fontSize, fontWeight, color: textColor, gap, height, marginInlineEnd, marginTop, control: { border, position = 'left', colors: { bg, inactiveInternalBg, disabled } = {}, focusVisible: { border: { color: focusVisibleBorderColor, } = {} } = {}, multiChoice: { borderRadius } = {}, singleChoice: { checkedSizeModifier, internalWidth } = {}, width } = {}, checkmark: { color, left, longSideWidth, shortSideWidth, top, weight } = {}, padding: { inlineStart, left: paddingLeft, top: paddingTop } = {}, } = {} } = choice;
    return `{
        ${alignItems ? `--choice-option-align-items: ${alignItems};` : ''}
        ${bg ? `--choice-option-control-bg-color: ${bg};` : ''}
        ${border ? `--view-default-control-border: ${border};` : ''}
        ${borderRadius ? `--multi-choice-option-control-border-radius: ${borderRadius};` : ''}
        ${focusVisibleBorderColor ? `--choice-option-control-focus-visible-border-color: ${focusVisibleBorderColor};` : ''}
        ${disabled ? `--choice-option-control-disabled-color: ${disabled};` : ''}
        ${disabledColor ? `--choice-option-label-disabled-color: ${disabledColor};` : ''}
        ${fontSize ? `--choice-option-label-font-size: ${fontSize};` : ''}
        ${fontWeight ? `--choice-option-label-font-weight: ${fontWeight};` : ''}
        ${gap ? `--choice-option-gap: ${gap};` : ''}
        ${height ? `--choice-option-height: ${height};` : ''}
        ${inactiveInternalBg ? `--choice-option-unchecked-bg: ${inactiveInternalBg};` : ''}
        ${internalWidth ? `--radiobox-size-m-after-size: ${internalWidth};` : ''}
        ${isNaN(Number(checkedSizeModifier)) ? '' : `--choice-option-checked-transform: scale(${checkedSizeModifier});`}
        ${color ? `--choice-option-checkmark-color: ${color};` : ''}
        ${left ? `--choice-option-checkmark-left: ${left};` : ''}
        ${longSideWidth ? `--choice-option-checkmark-long-side-width: ${longSideWidth};` : ''}
        ${marginInlineEnd ? `--choice-option-margin-inline-end: ${marginInlineEnd};` : ''}
        ${marginTop ? `--choice-option-margin-top: ${marginTop};` : ''}
        ${inlineStart ? `--choice-option-padding-inline-start: ${inlineStart};` : ''}
        ${textColor ? `--choice-option-text-color: ${textColor};` : ''}
        ${paddingLeft ? `--choice-option-padding-left: ${paddingLeft};` : ''}
        ${paddingTop ? `--choice-option-padding-top: ${paddingTop};` : ''}
        ${position === 'right' ? `--choice-option-flex-direction: row-reverse;` : ''}
        ${shortSideWidth ? `--choice-option-checkmark-short-side-width: ${shortSideWidth};` : ''}
        ${top ? `--choice-option-checkmark-top: ${top};` : ''}
        ${weight ? `--choice-option-checkmark-weight: ${weight};` : ''}
        ${width ? `--choice-option-control-width: ${width};` : ''}
    }`;
}
exports.default = compileChoiceStyleString;
