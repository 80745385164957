"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.router = exports.handlerClickPreviousPage = exports.handlerClickNextPage = exports.handlerScreenout = exports.handlerMoveNextPage = exports.canSendFinish = exports.sendFinish = exports.findPreviousPage = exports.findNextPage = exports.getAnswers = void 0;
const effects_1 = require("redux-saga/effects");
const question_1 = require("src/utils/question");
const actions_1 = require("../actions");
const selectors_1 = require("./selectors");
function* getAnswers() {
    const answersFromQueryParams = yield (0, effects_1.select)(selectors_1.getQueryParamsAnswers);
    const answeredQuestions = new Set();
    const answers = [];
    if (answersFromQueryParams && answersFromQueryParams.length) {
        answers.push(...answersFromQueryParams);
        answersFromQueryParams.map(({ questionId }) => {
            answeredQuestions.add(questionId.toString());
        });
    }
    const questions = yield (0, effects_1.select)(selectors_1.getQuestions);
    const charLimits = yield (0, effects_1.select)(selectors_1.getCharLimits);
    Object.keys(questions).map(key => {
        const id = key.toString();
        if (answeredQuestions.has(id))
            return;
        const question = (0, question_1.createQuestionModel)(questions, Number(id), { charLimits });
        const values = question.getValueAsObj();
        answers.push(Object.assign(Object.assign({}, values), { questionId: id }));
    });
    return answers;
}
exports.getAnswers = getAnswers;
function* findNextPage() {
    const currPageIndex = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    const pagesList = yield (0, effects_1.select)(selectors_1.getPages);
    const conditions = yield (0, effects_1.select)(selectors_1.getSurveyConditions);
    const nextPageIndex = yield (0, effects_1.call)(selectors_1.getNextPageIndex, { pagesList, currPageIndex, conditions });
    return nextPageIndex;
}
exports.findNextPage = findNextPage;
function* findPreviousPage() {
    const currPageIndex = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    const pagesList = yield (0, effects_1.select)(selectors_1.getPages);
    const prevPageIndex = yield (0, effects_1.call)(selectors_1.getPreviousPageIndex, { pagesList, currPageIndex });
    return prevPageIndex;
}
exports.findPreviousPage = findPreviousPage;
function* sendFinish() {
    const slug = yield (0, effects_1.select)(selectors_1.getSlug);
    const answers = yield (0, effects_1.call)(getAnswers);
    yield (0, effects_1.put)((0, actions_1.finishSurveyRequest)(slug, { answers }));
}
exports.sendFinish = sendFinish;
// eslint-disable-next-line complexity
function canSendFinish({ pages, questions, widgets, currPage }) {
    var _a;
    for (const page of pages.slice(currPage)) {
        if (!page.visible) {
            continue;
        }
        const hasBackBtn = (_a = page.backBtn) === null || _a === void 0 ? void 0 : _a.enabled;
        for (const item of page.children) {
            // eslint-disable-next-line max-depth
            if (item.type === 'question' && questions[item.id].visible) {
                return false;
            }
            // eslint-disable-next-line max-depth
            if (hasBackBtn && item.type === 'widget' && widgets[item.id].visible) {
                return false;
            }
        }
    }
    return true;
}
exports.canSendFinish = canSendFinish;
// eslint-disable-next-line max-statements, complexity
function* handlerMoveNextPage() {
    const currPage = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    if (currPage === -1) {
        yield (0, effects_1.put)((0, actions_1.leftLastPage)());
    }
    const isFinished = yield (0, effects_1.select)(selectors_1.getFinishState);
    const isScreenout = yield (0, effects_1.select)(selectors_1.getScreenoutState);
    if (isFinished || isScreenout) {
        return;
    }
    if (currPage === -1) {
        return yield (0, effects_1.call)(sendFinish);
    }
    const pages = yield (0, effects_1.select)(selectors_1.getPages);
    const questions = yield (0, effects_1.select)(selectors_1.getQuestions);
    const widgets = yield (0, effects_1.select)(selectors_1.getWidgets);
    const readyToFinish = yield (0, effects_1.call)(canSendFinish, { pages, questions, widgets, currPage });
    if (readyToFinish) {
        return yield (0, effects_1.call)(sendFinish);
    }
}
exports.handlerMoveNextPage = handlerMoveNextPage;
function* handlerScreenout() {
    const currPageIndex = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    yield (0, effects_1.put)((0, actions_1.moveNextPage)({ from: currPageIndex, to: -1 }));
}
exports.handlerScreenout = handlerScreenout;
// eslint-disable-next-line max-statements, complexity
function* handlerClickNextPage(params = {}) {
    const isLogicProcessing = yield (0, effects_1.select)(selectors_1.getLogicProcessing);
    if (isLogicProcessing)
        return;
    // @ts-expect-error TS(2339): Property 'skipValidation' does not exist on type '... Remove this comment to see the full error message
    const { skipValidation = false } = params;
    const invalidQuestions = yield (0, effects_1.select)(selectors_1.getInvalidQuestionsOnCurrentPage);
    if (!skipValidation && invalidQuestions.length) {
        yield (0, effects_1.put)((0, actions_1.showQuestionsErrors)(invalidQuestions));
        yield (0, effects_1.put)((0, actions_1.scrollToQuestion)(invalidQuestions[0]));
        return;
    }
    const conditions = yield (0, effects_1.select)(selectors_1.getSurveyConditions);
    if (conditions.skip) {
        return yield (0, effects_1.put)((0, actions_1.surveySkip)());
    }
    const currPageIndex = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    const currPageId = yield (0, effects_1.select)(selectors_1.getPageId, currPageIndex);
    const screenout = yield (0, effects_1.select)(selectors_1.getScreenout, currPageId);
    const isScreenout = screenout && !screenout.valid;
    const newPageIndex = yield (0, effects_1.call)(findNextPage);
    // Если это переход не на страницу "Спасибо", то логируем его
    if (newPageIndex !== -1 && !isScreenout) {
        const slug = yield (0, effects_1.select)(selectors_1.getSlug);
        const newPageId = yield (0, effects_1.select)(selectors_1.getPageId, newPageIndex);
        yield (0, effects_1.put)((0, actions_1.storePageSwitch)(slug, currPageId, newPageId));
    }
    yield (0, effects_1.put)((0, actions_1.sendAnswersToMetrika)());
    if (isScreenout) {
        const optionIds = yield (0, effects_1.select)(selectors_1.getCurrentPageSelectedOptions);
        const slug = yield (0, effects_1.select)(selectors_1.getSlug);
        const { search } = yield (0, effects_1.select)(selectors_1.getLocation);
        yield (0, effects_1.put)((0, actions_1.leftLastPage)());
        yield (0, effects_1.put)((0, actions_1.sendScreenout)({
            slug,
            pageFrom: currPageIndex,
            optionIds,
            queryString: search.substring(1)
        }));
        return;
    }
    yield (0, effects_1.put)((0, actions_1.moveNextPage)({ from: currPageIndex, to: newPageIndex }));
}
exports.handlerClickNextPage = handlerClickNextPage;
// eslint-disable-next-line max-statements, complexity
function* handlerClickPreviousPage() {
    const currPageIndex = yield (0, effects_1.select)(selectors_1.getCurrentPage);
    const currPageId = yield (0, effects_1.select)(selectors_1.getPageId, currPageIndex);
    const screenout = yield (0, effects_1.select)(selectors_1.getScreenout, currPageId);
    if (screenout) {
        return;
    }
    const newPageIndex = yield (0, effects_1.call)(findPreviousPage);
    if (newPageIndex !== -1) {
        const slug = yield (0, effects_1.select)(selectors_1.getSlug);
        const newPageId = yield (0, effects_1.select)(selectors_1.getPageId, newPageIndex);
        yield (0, effects_1.put)((0, actions_1.storePageSwitch)(slug, currPageId, newPageId));
    }
    yield (0, effects_1.put)((0, actions_1.moveNextPage)({ from: currPageIndex, to: newPageIndex }));
}
exports.handlerClickPreviousPage = handlerClickPreviousPage;
function* router() {
    yield (0, effects_1.takeEvery)('CLICK_PREVIOUS_PAGE', handlerClickPreviousPage);
    yield (0, effects_1.takeEvery)('CLICK_NEXT_PAGE', handlerClickNextPage);
    yield (0, effects_1.takeEvery)('MOVE_NEXT_PAGE', handlerMoveNextPage);
    yield (0, effects_1.takeEvery)('SURVEY_SCREENOUT_SUCCESS', handlerScreenout);
}
exports.router = router;
