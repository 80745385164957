"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileQuestionLabelStyleString(branding) {
    const { questionLabel = {} } = branding;
    const { fontSize, color: fontColor, fontWeight, lineHeight, requiredAsterisk: { color: requiredAsteriskColor } = {}, textAlign } = questionLabel;
    return `
        {
            ${fontSize ? `--question-label-font-size: ${fontSize};` : ''}
            ${fontColor ? `--question-label-font-color: ${fontColor};` : ''}
            ${fontWeight ? `--question-label-font-weight: ${fontWeight};` : ''}
            ${lineHeight ? `--question-label-line-height: ${lineHeight};` : ''}
            ${requiredAsteriskColor
        ? `--view-required-asterisk: ${requiredAsteriskColor}!important;`
        : ''}
            ${textAlign ? `--question-label-text-align: ${textAlign};` : ''}
        }
    `;
}
exports.default = compileQuestionLabelStyleString;
