"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileDateStyleString(branding) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21;
    const { date = {} } = branding;
    const { body, cell, fontFamily, header } = date;
    return `
        {
            ${(body === null || body === void 0 ? void 0 : body.bg) ? `--calendar-view-default-fill-color:${body.bg};` : ''}
            ${((_b = (_a = cell === null || cell === void 0 ? void 0 : cell.base) === null || _a === void 0 ? void 0 : _a.border) === null || _b === void 0 ? void 0 : _b.color) ? `--calendar-view-default-cell-border-color-base:${cell.base.border.color};` : ''}
            ${((_c = cell === null || cell === void 0 ? void 0 : cell.border) === null || _c === void 0 ? void 0 : _c.radius) ? `--calendar-view-default-cell-border-radius:${cell.border.radius};` : ''}
            ${((_e = (_d = cell === null || cell === void 0 ? void 0 : cell.base) === null || _d === void 0 ? void 0 : _d.border) === null || _e === void 0 ? void 0 : _e.width) ? `--calendar-view-default-cell-border-width-base:${cell.base.border.width};` : ''}
            ${((_f = cell === null || cell === void 0 ? void 0 : cell.disabled) === null || _f === void 0 ? void 0 : _f.color) ? `--calendar-view-default-cell-typo-color-disabled:${cell.disabled.color};` : ''}
            ${((_j = (_h = (_g = cell === null || cell === void 0 ? void 0 : cell.base) === null || _g === void 0 ? void 0 : _g.hover) === null || _h === void 0 ? void 0 : _h.border) === null || _j === void 0 ? void 0 : _j.color) ? `--calendar-view-default-cell-border-color-base-hovered:${cell.base.hover.border.color};` : ''}
            ${((_l = (_k = cell === null || cell === void 0 ? void 0 : cell.base) === null || _k === void 0 ? void 0 : _k.hover) === null || _l === void 0 ? void 0 : _l.bg) ? `--calendar-view-default-cell-fill-color-hovered:${cell.base.hover.bg};` : ''}
            ${((_p = (_o = (_m = cell === null || cell === void 0 ? void 0 : cell.base) === null || _m === void 0 ? void 0 : _m.pressed) === null || _o === void 0 ? void 0 : _o.border) === null || _p === void 0 ? void 0 : _p.color) ? `--calendar-view-default-cell-border-color-base-pressed:${cell.base.pressed.border.color};` : ''}
            ${((_r = (_q = cell === null || cell === void 0 ? void 0 : cell.base) === null || _q === void 0 ? void 0 : _q.pressed) === null || _r === void 0 ? void 0 : _r.bg) ? `--calendar-view-default-cell-fill-color-pressed:${cell.base.pressed.bg};` : ''}
            ${((_s = cell === null || cell === void 0 ? void 0 : cell.selected) === null || _s === void 0 ? void 0 : _s.bg) ? ` --calendar-view-default-cell-fill-color-selected-base:${cell.selected.bg};` : ''}
            ${((_u = (_t = cell === null || cell === void 0 ? void 0 : cell.selected) === null || _t === void 0 ? void 0 : _t.border) === null || _u === void 0 ? void 0 : _u.color) ? `--calendar-view-default-cell-border-color-selected:${cell.selected.border.color};` : ''}
            ${((_w = (_v = cell === null || cell === void 0 ? void 0 : cell.selected) === null || _v === void 0 ? void 0 : _v.border) === null || _w === void 0 ? void 0 : _w.width) ? `--calendar-view-default-cell-border-width-selected:${cell.selected.border.width};` : ''}
            ${((_x = cell === null || cell === void 0 ? void 0 : cell.selected) === null || _x === void 0 ? void 0 : _x.color) ? `--calendar-view-default-cell-typo-color-selected-base:${cell.selected.color};` : ''}
            ${((_z = (_y = cell === null || cell === void 0 ? void 0 : cell.selected) === null || _y === void 0 ? void 0 : _y.hover) === null || _z === void 0 ? void 0 : _z.bg) ? `--calendar-view-default-cell-fill-color-selected-hovered:${cell.selected.hover.bg};` : ''}
            ${((_2 = (_1 = (_0 = cell === null || cell === void 0 ? void 0 : cell.selected) === null || _0 === void 0 ? void 0 : _0.hover) === null || _1 === void 0 ? void 0 : _1.border) === null || _2 === void 0 ? void 0 : _2.color) ? `--calendar-view-default-cell-border-color-selected-hovered:${cell.selected.hover.border.color};` : ''}
            ${((_4 = (_3 = cell === null || cell === void 0 ? void 0 : cell.selected) === null || _3 === void 0 ? void 0 : _3.pressed) === null || _4 === void 0 ? void 0 : _4.bg) ? `--calendar-view-default-cell-fill-color-selected-pressed:${cell.selected.pressed.bg};` : ''}
            ${((_7 = (_6 = (_5 = cell === null || cell === void 0 ? void 0 : cell.selected) === null || _5 === void 0 ? void 0 : _5.pressed) === null || _6 === void 0 ? void 0 : _6.border) === null || _7 === void 0 ? void 0 : _7.color) ? `--calendar-view-default-cell-border-color-selected-pressed:${cell.selected.pressed.border.color};` : ''}
            ${((_9 = (_8 = cell === null || cell === void 0 ? void 0 : cell.today) === null || _8 === void 0 ? void 0 : _8.underline) === null || _9 === void 0 ? void 0 : _9.color) ? ` --calendar-view-default-cell-underline-color-today:${cell.today.underline.color};` : ''}
            ${((_11 = (_10 = cell === null || cell === void 0 ? void 0 : cell.today) === null || _10 === void 0 ? void 0 : _10.underline) === null || _11 === void 0 ? void 0 : _11.width) ? `--calendar-view-default-cell-border-width-today:${cell.today.underline.width};` : ''}
            ${((_13 = (_12 = cell === null || cell === void 0 ? void 0 : cell.base) === null || _12 === void 0 ? void 0 : _12.weekdays) === null || _13 === void 0 ? void 0 : _13.color) ? ` --calendar-view-default-cell-typo-color-base:${(_14 = cell.base) === null || _14 === void 0 ? void 0 : _14.weekdays.color};` : ''}
            ${((_16 = (_15 = cell === null || cell === void 0 ? void 0 : cell.base) === null || _15 === void 0 ? void 0 : _15.weekends) === null || _16 === void 0 ? void 0 : _16.color) ? ` --calendar-view-default-cell-typo-color-weekend:${(_17 = cell.base) === null || _17 === void 0 ? void 0 : _17.weekends.color};` : ''}
            ${fontFamily ? `--typography-font-family:${fontFamily};` : ''}
            ${(header === null || header === void 0 ? void 0 : header.bg) ? `--calendar-view-default-header-fill-color:${header.bg};` : ''}
            ${((_19 = (_18 = header === null || header === void 0 ? void 0 : header.daysOfWeek) === null || _18 === void 0 ? void 0 : _18.weekends) === null || _19 === void 0 ? void 0 : _19.color) ? `--calendar-view-default-weekend-typo-color-base:${header.daysOfWeek.weekends.color};` : ''}
            ${((_21 = (_20 = header === null || header === void 0 ? void 0 : header.daysOfWeek) === null || _20 === void 0 ? void 0 : _20.weekdays) === null || _21 === void 0 ? void 0 : _21.color) ? `--calendar-view-default-weekday-typo-color-base:${header.daysOfWeek.weekdays.color};` : ''}
        }
    `;
}
exports.default = compileDateStyleString;
