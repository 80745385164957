"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.catchFirstAnswer = exports.router = exports.checkAndSend = exports.getContainerHeight = exports.sendWebkitMessage = exports.sendPostMessage = exports.sendEvent = exports.prepareData = void 0;
const effects_1 = require("redux-saga/effects");
const frame_1 = require("src/utils/frame");
const root_1 = require("src/utils/root");
const webview_1 = require("src/utils/webview");
const consts_1 = require("./consts");
const selectors_1 = require("./selectors");
// eslint-disable-next-line complexity, max-params
function prepareData(action, slug, questions, height, displayHiddenQuestions) {
    const { type, status, data, url } = action;
    const { reason } = data !== null && data !== void 0 ? data : {};
    const params = {};
    switch (type) {
        case 'SURVEY_GET_SUCCESS':
        case 'SURVEY_RESTORED_FROM_PRELOADED_STATE':
        case 'SURVEY_LOADED_FROM_CONFIG':
            params.event = frame_1.EVENTS.RENDERED;
            params.payload = { height };
            break;
        case 'SURVEY_GET_FAILURE':
        case 'SURVEY_SKIP':
            params.event = frame_1.EVENTS.REJECTED;
            params.payload = { status, reason };
            break;
        case 'SURVEY_SCREENOUT_SUCCESS':
            params.event = frame_1.EVENTS.SCREENOUT;
            break;
        case 'SURVEY_FINISH_SUCCESS':
            params.event = frame_1.EVENTS.FINISHED;
            break;
        case 'SURVEY_FIRST_ANSWER':
            params.event = frame_1.EVENTS.FIRST_ANSWER;
            params.payload = { slug, questions };
            break;
        case 'PAGE_SWITCHED':
            params.event = frame_1.EVENTS.PAGE_SWITCHED;
            params.payload = { pageNumber: data.pageNumber, height };
            break;
        case 'RESIZE':
            params.event = frame_1.EVENTS.HEIGHT_CHANGED;
            params.payload = { height };
            break;
        case 'LEFT_LAST_PAGE':
            params.event = frame_1.EVENTS.LEFT_LAST_PAGE;
            params.payload = { slug, questions };
            break;
        case 'REDIRECT':
            params.event = frame_1.EVENTS.REDIRECTED;
            params.payload = { url };
            break;
        case 'TEXT_RESIZED_TO_MIN':
            params.event = frame_1.EVENTS.TEXT_RESIZED_TO_MIN;
            break;
        case 'TOGGLE_HIDDEN_QUESTIONS_DISPLAY':
            params.event = frame_1.EVENTS.DISPLAY_HIDDEN_QUESTIONS;
            params.payload = { displayHiddenQuestions };
            break;
        default:
            break;
    }
    return params;
}
exports.prepareData = prepareData;
function sendEvent(data, root) {
    const event = new CustomEvent(data.event, { detail: data.payload });
    root.dispatchEvent(event);
}
exports.sendEvent = sendEvent;
function sendPostMessage(data) {
    data = JSON.stringify(data);
    window.parent.postMessage(data, '*');
}
exports.sendPostMessage = sendPostMessage;
function sendWebkitMessage(data) {
    var _a, _b, _c;
    (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.webkit) === null || _a === void 0 ? void 0 : _a.messageHandlers) === null || _b === void 0 ? void 0 : _b.survey) === null || _c === void 0 ? void 0 : _c.postMessage(JSON.stringify(data));
}
exports.sendWebkitMessage = sendWebkitMessage;
function* getContainerHeight() {
    const container = yield (0, effects_1.call)(root_1.getRoot);
    return container.scrollHeight;
}
exports.getContainerHeight = getContainerHeight;
function* checkAndSend(action) {
    const root = yield (0, effects_1.call)(root_1.getRoot);
    const slug = yield (0, effects_1.select)(selectors_1.getSlug);
    const questions = yield (0, effects_1.select)(selectors_1.getQuestions);
    const height = yield (0, effects_1.call)(getContainerHeight);
    const displayHiddenQuestions = yield (0, effects_1.select)(selectors_1.getDisplayHiddenQuestions);
    const data = yield (0, effects_1.call)(prepareData, action, slug, questions, height, displayHiddenQuestions);
    yield (0, effects_1.call)(sendEvent, data, root);
    yield (0, effects_1.call)(sendPostMessage, data);
    if ((0, webview_1.isInWebview)())
        yield (0, effects_1.call)(sendWebkitMessage, data);
}
exports.checkAndSend = checkAndSend;
function* router() {
    yield (0, effects_1.takeEvery)([
        'SURVEY_GET_SUCCESS',
        'SURVEY_RESTORED_FROM_PRELOADED_STATE',
        'SURVEY_GET_FAILURE',
        'SURVEY_SCREENOUT_SUCCESS',
        'SURVEY_FINISH_SUCCESS',
        'SURVEY_FIRST_ANSWER',
        'PAGE_SWITCHED',
        'RESIZE',
        'LEFT_LAST_PAGE',
        'SURVEY_SKIP',
        'REDIRECT',
        'TEXT_RESIZED_TO_MIN',
        'TOGGLE_HIDDEN_QUESTIONS_DISPLAY'
    ], checkAndSend);
    yield (0, effects_1.call)(catchFirstAnswer);
}
exports.router = router;
// Ловим первый(!) ответ на вопрос, остальные игнорируем
function* catchFirstAnswer() {
    yield (0, effects_1.take)(consts_1.QUESTION_ANSWER_ACTIONS);
    yield (0, effects_1.call)(checkAndSend, { type: 'SURVEY_FIRST_ANSWER' });
}
exports.catchFirstAnswer = catchFirstAnswer;
