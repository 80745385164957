"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.question = void 0;
/* eslint-disable import/prefer-default-export */
const react_1 = require("@emotion/react");
const question = (showType) => (0, react_1.css) `
    position: relative;
    border: none;
    padding: 0;
    outline: none;

    .question__required {
        color: var(--error-color);
        margin: 0 0 0 0.3em;
    }

    .question__error {
        color: var(--error-color);
        right: 0;
        font-size: var(--error-font-size);
        font-weight: var(--error-font-weight);
        line-height: var(--error-line-height);
        margin: var(--error-margin);
    }

    .question__option label {
        cursor: pointer;
    }

    .y-input_textarea .y-input__control {
        min-height: var(--long-text-textarea-min-height) !important;
    }

    .question__label {
        margin-bottom: 1.5em;
        text-align: var(--question-label-text-align);
        line-height: var(--question-label-line-height);
        font-size: var(--question-label-font-size);
        font-weight: var(--question-label-font-weight);
        color: var(--question-label-font-color);
    }

    .question__label_with-caption_yes {
        margin-bottom: -0.2em;
    }

    .question__caption {
        color: var(--question-caption-color);
        font-size: var(--question-caption-font-size);
        font-weight: var(--question-caption-font-weight);
        margin-bottom: var(--question-caption-margin-bottom);
        margin-top: var(--question-caption-margin-top);
        padding-left: var(--question-caption-padding-left);
    }

    .question__label_required_yes .markdown > *:last-child::${showType === 'none'
    ? 'after'
    : showType} {
        ${showType === 'none' ? 'display: none;' : ''}
        content: '*' / '';

        margin-inline-start: 0.15em;
        color: var(--view-required-asterisk);
    }

    .question__side-image {
        display: inline-block;
        box-sizing: border-box;
        width: 50%;
        vertical-align: middle;
    }

    .question_badge {
        margin-bottom: 12px;
        margin-right: 12px;
    }

    &.question_hidden {
        background-color: rgba(246, 248, 251, 1);
        padding: 16px 12px;
        border-radius: 12px;
        position: relative;
        margin-inline: -12px;
        width: 100%;
    }

    .question__content-wrapper_side-align_yes {
        display: inline-block;
        box-sizing: border-box;
        width: 50%;
        padding-inline-start: 5%;
        vertical-align: middle;
    }

    .question__content-wrapper_side-align_yes .question__label .markdown * {
        text-align: start;
    }

    .question__content-wrapper_side-align_yes .choice__option {
        width: 100% !important;
    }

    /* Стили для ugc-cab */
    &.question_theme_ugc-cab .question__label,
    &.question_theme_ugc-cab .question__caption {
        display: none;
    }

    /* Стили для video */
    &.question_theme_video .question__label {
        width: 90%;

        margin-bottom: 1em;
    }
    &.question_theme_video .question__content-wrapper_side-align_yes {
        padding-inline-start: 2%;
    }

    &.question_theme_video .choice {
        &__option {
            margin: 0 2em 0.5em 0;

            background: rgba(255, 255, 255, 0.1);
            border-radius: 2px;

            /* Внутри опшенсов текст выравниваем от левого края */
            .markdown * {
                text-align: start !important;
                color: #f6f5f3 !important;
            }
        }

        &__option_checked {
            background: white;
        }

        &__option-label {
            display: flex;
            align-items: center;

            width: 100%;

            padding: 1em;
            box-sizing: border-box;
        }

        &__option-control {
            width: 3em;
            height: 3em;
            border-color: #fff;
        }

        &__option-control_multiple_yes:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect width='28' height='28' x='1' y='1' stroke='%23FFF' stroke-width='2' opacity='.255' rx='2'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E") !important;
        }

        &__option-control_multiple_yes.choice__option-control_checked:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect width='28' height='28' x='1' y='1' stroke='%23000' stroke-width='2' opacity='.255' rx='2'/%3E%3Cpath stroke='%23000' stroke-width='3' d='M6 16.275l5.852 4.572L23.698 9'/%3E%3C/g%3E%3C/svg%3E") !important;
        }

        &__option-control_multiple_no:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='15' cy='15' r='14' stroke='%23FFF' stroke-width='2' opacity='.255'/%3E%3C/g%3E%3C/svg%3E") !important;
        }

        &__option-control_multiple_no.choice__option-control_checked:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='15' cy='15' r='14' stroke='%23000' stroke-width='2' opacity='.255'/%3E%3Ccircle cx='15' cy='15' fill='%23000' r='6'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E") !important;
        }

        &__option-control_multiple_yes.choice__option-control_checked
            + .choice__option-text
            .markdown
            *,
        &__option-control_multiple_no.choice__option-control_checked
            + .choice__option-text
            .markdown
            * {
            color: #000 !important;
        }

        &__option-text {
            flex: 1;

            max-height: none;
            padding: 0;
        }

        & .choice__other-answer {
            margin-top: 0;
            margin-bottom: 1.5em;
        }
    }

    /* Стили для transparent */
    &.question_theme_transparent {
        & .question__content {
            @media (orientation: landscape) and (max-height: 200px) {
                padding-top: 1.5em;
                padding-bottom: 1.5em;
            }

            & .question__error {
                display: block;
            }
        }
    }

    &.question_theme_transparent .choice {
        @media (orientation: landscape) {
            text-align: start;

            &__option {
                display: inline-block;
                width: 50%;
            }
        }

        @media (orientation: landscape) and (max-height: 200px) {
            padding-top: 0.2em;
        }

        @media (orientation: portrait) and (max-width: 200px) {
            padding-top: 0.2em;
        }

        &__option {
            padding: 0;
            margin-top: 0;
            margin-bottom: 1.5em;
            vertical-align: top;

            @media (orientation: landscape) and (max-height: 200px) {
                margin-top: 0;
            }

            @media (orientation: portrait) and (max-width: 200px) {
                margin-top: 0;
            }

            /* Внутри опшенсов текст выравнивам от левого края */
            & .markdown * {
                text-align: start !important;
            }
        }

        &__option-label {
            & .markdown p {
                color: #fff !important;
            }
        }

        &__option-control {
            width: 2.6em;
            height: 2.6em;
            border-color: #fff;
        }

        &__option-control_multiple_yes:before {
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiIHZpZXdCb3g9IjAgMCAzMCAzMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPHJlY3QgaWQ9IkNvbWJpbmVkLVNoYXBlIiBzdHJva2U9IiNGRkZGRkYiIHg9IjAuNSIgeT0iMC41IiB3aWR0aD0iMjkiIGhlaWdodD0iMjkiPjwvcmVjdD4gICAgPC9nPjwvc3ZnPg==) !important;
        }

        &__option-control_multiple_yes.choice__option-control_checked:before {
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiIHZpZXdCb3g9IjAgMCAzMCAzMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZTwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz4gICAgICAgIDxwYXRoIGQ9Ik0wLDAgTDMwLDAgTDMwLDMwIEwwLDMwIEwwLDAgWiBNMTEuOTk5OTkzOSwxOS4yIEw3Ljc5OTk5MzksMTUgTDYuMzk5OTkzOSwxNi40IEwxMS45OTk5OTM5LDIyIEwyMy45OTk5OTM5LDEwIEwyMi41OTk5OTM5LDguNiBMMTEuOTk5OTkzOSwxOS4yIFoiIGlkPSJwYXRoLTEiPjwvcGF0aD4gICAgPC9kZWZzPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJBcnRib2FyZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIwLjAwMDAwMCwgLTE3Ni4wMDAwMDApIj4gICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAuMDAwMDAwLCAxNzYuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxnIGlkPSJDb21iaW5lZC1TaGFwZSI+ICAgICAgICAgICAgICAgICAgICA8dXNlIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+ICAgICAgICAgICAgICAgICAgICA8cGF0aCBzdHJva2U9IiNGRkZGRkYiIHN0cm9rZS13aWR0aD0iMSIgZD0iTTAuNSwwLjUgTDAuNSwyOS41IEwyOS41LDI5LjUgTDI5LjUsMC41IEwwLjUsMC41IFogTTExLjk5OTk5MzksMTguNDkyODkzMiBMMjIuNTk5OTkzOSw3Ljg5Mjg5MzIyIEwyNC43MDcxMDA3LDEwIEwxMS45OTk5OTM5LDIyLjcwNzEwNjggTDUuNjkyODg3MTIsMTYuNCBMNy43OTk5OTM5LDE0LjI5Mjg5MzIgTDExLjk5OTk5MzksMTguNDkyODkzMiBaIj48L3BhdGg+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=) !important;
        }

        &__option-control_multiple_no:before {
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5jaXJjbGU8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJjaXJjbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiPiAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwtMiIgY3g9IjE1IiBjeT0iMTUiIHI9IjEwIj48L2NpcmNsZT4gICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsLTMiIHN0cm9rZT0iI0ZGRkZGRiIgY3g9IjE1IiBjeT0iMTUiIHI9IjE1Ij48L2NpcmNsZT4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==) !important;
        }

        &__option-control_multiple_no.choice__option-control_checked:before {
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5jaXJjbGU8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJjaXJjbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiIHN0cm9rZT0iI0ZGRkZGRiI+ICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbC0yIiBmaWxsPSIjRkZGRkZGIiBjeD0iMTUiIGN5PSIxNSIgcj0iMTAiPjwvY2lyY2xlPiAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwtMyIgY3g9IjE1IiBjeT0iMTUiIHI9IjE1Ij48L2NpcmNsZT4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==) !important;
        }

        &__option-text {
            max-height: none;
            padding-top: 0.7em;
            padding-inline-end: 1em;
            box-sizing: border-box;
        }

        & .choice__other-answer {
            margin-top: 0;
            margin-bottom: 1.5em;
        }
    }
`;
exports.question = question;
