"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileButtonStyleString(branding) {
    const { button = {} } = branding;
    const { bgColor, borderRadius, disabled: { bgColor: disabledBgColor, color: disabledTextColor, textOpacity } = {}, focusVisible: { border: { color: focusVisibleBorderColor, } = {} } = {}, fontSize, fontWeight, height, hover: { bgColor: hoverBgColor, } = {}, minWidth, margin: { top, between } = {} } = button;
    return `{
        ${bgColor ? `--button-action-bg: ${bgColor};` : ''}
        ${borderRadius ? `--button-action-border-radius: ${borderRadius};` : ''}
        ${disabledBgColor ? `--button-disabled-bg: ${disabledBgColor};` : ''}
        ${disabledTextColor ? `--button-disabled-text-color: ${disabledTextColor};` : ''}
        ${textOpacity ? `--button-disabled-text-opacity: ${textOpacity};` : ''}
        ${focusVisibleBorderColor ? `--button-focus-visible-border-color: ${focusVisibleBorderColor};` : ''}
        ${fontSize ? `--button-r-font-size: ${fontSize};` : ''}
        ${height ? `--button-r-height: ${height};` : ''}
        ${hoverBgColor ? `--button-action-hover-bg: ${hoverBgColor};` : ''}
        ${minWidth ? `--button-action-min-width: ${minWidth};` : ''}
        ${top ? `--button-action-margin-top: ${top};` : ''}
        ${between ? `--button-action-margin-between: ${between};` : ''}
        ${fontWeight ? `
            --button-font-weight: ${fontWeight};
            --button-action-font-weight: ${fontWeight};
        ` : ''}
    }`;
}
exports.default = compileButtonStyleString;
