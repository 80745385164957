"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    html {
        /* в лего размеры в rem, в app.styles font-size=100px, поэтому правим здесь */
        font-size: 16px;
    }

    /* Убираем дефолт иконку календаря  */

    input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        pointer-events: none;
    }

    input[type='date'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    /* Фикс дефолт иконки для Firefox */
    .question-date__input-wrap::after {
        content: '';
        z-index: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background: var(
            --page-bg
        ); /* Без этого не получается спрятать иконку в не webkit браузерах :( */
        pointer-events: none;
    }

    .question-date__input-wrap {
        position: relative;
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
    }

    .question-date__calendar-icon {
        position: absolute;
        background: transparent;
        border: none;
        cursor: pointer;
        z-index: 100;
        padding-right: 20px;
    }

    .question-date__input-wrap .input {
        width: 100%;
    }

    /* Чтобы в превью попап не перекрывал модалку */
    .Popup2_view_default {
        z-index: 100;
    }

    /* Новые свойства для лего календаря */

    .question-date__calendar-wrap .Calendar-DateButton::before {
        border: var(--calendar-view-default-cell-border-width-base) solid;
        border-color: var(--calendar-view-default-cell-border-color-base);
    }
    .question-date__calendar-wrap .Calendar-DateButton:hover::before {
        border-color: var(--calendar-view-default-cell-border-color-base-hovered);
    }

    .question-date__calendar-wrap .Calendar-DateButton_selected::before {
        border: var(--calendar-view-default-cell-border-width-selected) solid;
        border-color: var(--calendar-view-default-cell-border-color-selected);
    }
    .question-date__calendar-wrap .Calendar-DateButton_selected:hover::before {
        border-color: var(--calendar-view-default-cell-border-color-selected-hovered);
    }

    .question-date__calendar-wrap .Calendar_view_default .Calendar-DateButton:active::before {
        border-color: var(--calendar-view-default-cell-border-color-base-pressed);
    }
    .question-date__calendar-wrap
        .Calendar_view_default
        .Calendar-DateButton_selected[class]:active::before {
        border-color: var(--calendar-view-default-cell-border-color-selected-pressed);
    }

    .question-date__calendar-wrap .Calendar_view_default .Calendar-DateButton_today .Calendar-Date {
        border-bottom: var(--calendar-view-default-cell-border-width-today) solid;
        /* старый параметр, чтобы корректно выставлялся цвет */
        border-color: var(--calendar-view-default-cell-underline-color-today);
    }
`;
